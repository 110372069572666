import React, { Component } from 'react';
import './youtube.css';

export default class Main extends Component {
    render() {
       
        return (
            <div className="youtube1_page_section" id="youtube1">
                    <div className="youtube_content_container">  
                        <div className="youtube_headline">MY MESSAGE</div>
                        <div className="youtube_player">
                            <iframe width="100%" height="100%" margin="0" padding="0" src="https://www.youtube.com/embed/0S1QPBnp8BM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
            
                    </div>   
                
            </div>
        )
    }
}
