import React, {Component} from 'react';
import Navigation from './components/Navigation';
import Main from './sections/Main';
import Philanthropy from './sections/Philanthropy';
import Recognitions from './sections/Recognitions';
import Youtube from './sections/Youtube1';
import Travels from './sections/Travels';
import Running from './sections/Running';
import Photos from './sections/Photos';
import About from './sections/About';
import Contact from './sections/Contact';
import Footer from './sections/Footer';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Navigation />
        <Main />
        <Philanthropy />
        <Recognitions />
        <Youtube />
        <Travels />
        <Running />  
        <Photos />         
        <About />
        <Contact />
        <Footer />
      </div>
    );
  }
}

export default App;
