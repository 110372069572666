import React, { Component } from 'react';
import mainBgImage from './images-sections/bg-pic-philant.jpg';
import philBoxImg01 from './images-sections/philbox-img01.jpg';
import philBoxImg02 from './images-sections/philbox-img02.jpg';
import philBoxImg03 from './images-sections/philbox-img03.jpg';
import philBoxImg04 from './images-sections/philbox-img04.jpg';
import './philanthropy.css';


export default class Philanthropy extends Component {
    render() {
        let style = {
            backgroundImage: `url(${mainBgImage})`
        }
        return (
            <div className="phil_page_section" id="philanthropy">
                <div className='phil_section_background'>
                <div className="phil_content_container">  
                    <div className="phil_columns_container">
                        <div className="phil_headline">Care More</div>
                        <div className="phil_paragraph_section">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo 
                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse 
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non 
                                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>                        
                        </div>
                        <div className='phil_column_box'>
                            <img className='phil_box_image' alt='' src={philBoxImg01} />
                            <div className="phil_column_box_title">CHARITY ISSUE</div>                            
                            <p className='phil_paragraph_box'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo 
                                consequat. 
                            </p>	                            
                        </div>                    
                        <div className="phil_column_box">
                            <img className='phil_box_image' alt='' src={philBoxImg02} />
                            <div className="phil_column_box_title">CHARITY ISSUE</div>
                            <p className='phil_paragraph_box'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo 
                                consequat. 
                            </p>
                        </div>
                        <div className="phil_column_box">
                            <img className='phil_box_image' alt='' src={philBoxImg03} />
                            <div className="phil_column_box_title">CHARITY ISSUE</div>
                            <p className='phil_paragraph_box'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo 
                                consequat. 
                            </p>
                        </div>
                        <div className="phil_column_box">
                            <img className='phil_box_image' alt='' src={philBoxImg04} />
                            <div className="phil_column_box_title">CHARITY ISSUE</div>
                            <p className='phil_paragraph_box'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo 
                                consequat. 
                            </p>
                        </div>
                    </div>            
                </div>  
                </div>                
            </div>
        )
    }
}
